<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th>Hostname</th>
                <td colspan="2">
                  {{ asup.hostname }}
                </td>
              </tr>
              <tr>
                <th>Version</th>
                <td colspan="2">
                  {{ asup.version }}
                </td>
              </tr>
              <tr>
                <th>Serial</th>
                <td colspan="2">
                  {{ asup.serial }}
                </td>
              </tr>
              <tr v-if="asup.details.nodes.length === 1">
                <th>Uptime</th>
                <td colspan="2">
                  {{ asup.details.nodes[0].uptimeInDays }} days
                </td>
              </tr>
              <tr>
                <th>System Timezone</th>
                <td colspan="2">
                  {{ asup.timezone }}
                </td>
              </tr>
              <tr>
                <th>Last autosupport data</th>
                <td colspan="2">
                  {{ asup.updateTimestampUtc | formatDateTime }}
                </td>
              </tr>
              <tr v-if="asup.updateTimestampUtc > asup.lastBuildModelTimestamp">
                <th>Model built on (UTC)</th>
                <td colspan="2">
                  {{ asup.lastBuildModelTimestamp | formatDateTime }}<br>
                  <small class="text-hint text-danger">The displayed data is older than the latest Autosupport information. The displayed data should be updated a few minutes after the latest Autosupport data.</small>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>

        <b-card
          v-if="asup.details.metrics && (asup.details.metrics.slaMetric.inCompliancePolicyCount > 0 || asup.details.metrics.slaMetric.outOfCompliancePolicyCount > 0 || asup.details.metrics.slaMetric.inComplianceAssetCount > 0 || asup.details.metrics.slaMetric.outOfComplianceAssetCount > 0)"
          title="Compliance"
        >
          <div class="row">
            <div class="col-12 col-lg-6">
              <ComplianceMetric
                v-if="asup.details.metrics.slaMetric.inCompliancePolicyCount > 0 || asup.details.metrics.slaMetric.outOfCompliancePolicyCount > 0"
                :compliant-count="asup.details.metrics.slaMetric.inCompliancePolicyCount"
                :not-compliant-count="asup.details.metrics.slaMetric.outOfCompliancePolicyCount"
                title="Policies"
              />
            </div>
            <div class="col-12 col-lg-6">

              <ComplianceMetric
                v-if="asup.details.metrics.slaMetric.inComplianceAssetCount > 0 || asup.details.metrics.slaMetric.outOfComplianceAssetCount > 0"
                :compliant-count="asup.details.metrics.slaMetric.inComplianceAssetCount"
                :not-compliant-count="asup.details.metrics.slaMetric.outOfComplianceAssetCount"
                title="Assets"
              />
            </div>
          </div>
        </b-card>

        <b-card
          v-if="asup.details.metrics"
          title="Health"
        >
          <HealthMetric :asup="asup" />
        </b-card>

      </b-col>
      <b-col>
        <b-card>
          <template #header>
            <h4 class="card-title">
              Monitor Status <small class="text-muted">({{ monitors.length }} monitors checked)</small>
            </h4>
            <div
              v-if="isMonitorOverwritten"
              class="float-right"
            >
              <b-badge
                v-b-popover.hover="'At least one monitor has been overwritten for this system.'"
                pill
                variant="info"
              >
                <small>Monitor overwritten <feather-icon icon="HelpCircleIcon" /></small>
              </b-badge>
            </div>
          </template>

          <ul
            v-if="activeMonitors.length > 0"
            style="list-style: none"
          >
            <li
              v-for="monitor in activeMonitors"
              :id="`monitor_${monitor.monitorId}`"
              :key="monitor.monitorId"
            >
              <monitor-status-icon
                :status="monitor.status"
                :text="monitor.shortMessage || monitor.exceptionMessage"
              />

              <b-popover
                custom-class="wide-popover"
                :target="`monitor_${monitor.monitorId}`"
                triggers="hover"
                :variant="popoverVariant(monitor.status)"
              >
                <template #title>
                  Detailed message
                </template>

                <span
                  v-if="monitor.message"
                  v-html="monitor.message"
                />
                <span v-else>No detailed message</span>
              </b-popover>
            </li>
          </ul>
          <div v-else>
            <monitor-status-icon
              :status="0"
              text="All monitors are OK"
            />
          </div>
        </b-card>

        <b-card
          :title="`Services (${orderedServices.length})`"
        >
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th />
                  <th>Service</th>
                  <th>Monitored</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="service in orderedServices"
                  :key="service.id"
                >
                  <td>
                    <b-badge :variant="getServiceStatusVariantName(service.status)">
                      {{ service.status }}
                    </b-badge>
                  </td>
                  <td>
                    {{ service.componentDisplayName }}<br>
                    <small class="text-muted">{{ service.componentServiceName }}</small>
                  </td>
                  <td>{{ service.monitored }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, VBPopover, BPopover, BBadge,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import MonitorStatusIcon from '@/components/monitor/MonitorStatusIcon.vue'
import ComplianceMetric from './components/ComplianceMetric.vue'
import HealthMetric from './components/HealthMetric.vue'

import AsupStatusService from '@/service/asupStatus.service'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    MonitorStatusIcon,
    BPopover,
    BBadge,
    ComplianceMetric,
    HealthMetric,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      monitors: [],
      isLoading: false,
      capacityChartOptions: {
        chart: {
          type: 'radialBar',
          width: 50,
          height: 50,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: '50%',
            },
            track: {
              margin: 0,
            },
            dataLabels: {
              show: false,
            },
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
    orderedServices() {
      // sort services by Status (red first, then Disabled, then by display name)
      const comparer = (a, b) => {
        if (a.status !== 'DISABLED' && b.status === 'DISABLED') {
          return -1
        }
        if (a.status !== 'UP' && b.status === 'UP') {
          return -1
        }
        if (a.componentDisplayName < b.componentDisplayName) {
          return -1
        }

        return 1
      }

      return this.asup.details.services
        .concat()
        // eslint-disable-next-line no-nested-ternary
        .sort(comparer)
    },
    activeMonitors() {
      return this.monitors.filter(x => x.status !== 0)
    },
    isMonitorOverwritten() {
      if (this.isHost && this.asup.isHostMonitorOverwritten) {
        return true
      }
      if (!this.isHost && this.asup.isTenantMonitorOverwritten) {
        return true
      }

      return false
    },
  },
  created() {
    AsupStatusService.getActiveListAsync({ asupSystemId: this.asup.id })
      .then(result => {
        this.monitors = result.items
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    popoverVariant(status) {
      switch (status) {
        case 0: return 'success'
        case 10: return 'info'
        case 20: return 'warning'
        case 30:
          return 'danger'
        default: return 'danger'
      }
    },
    getServiceStatusVariantName(serviceStatus) {
      switch (serviceStatus) {
        case 'UP': return 'success'
        case 'DISABLED': return 'secondary'
        default: return 'danger'
      }
    },
  },
}
</script>

<style scoped>
  .success {
    fill: var(--success);
    color: var(--success);
  }
  .danger {
    fill: var(--danger);
    color: var(--danger);
  }
  .config-table-wrapper {
    max-height: 400px;
    overflow-y: auto
  }
</style>
