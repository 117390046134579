<template>
  <div>
    <Grid
      :data-items="result"
      :columns="columns"
      :column-menu="true"
      :take="dataState.take"
      :skip="dataState.skip"
      :sort="dataState.sort"
      :filter="dataState.filter"
      :group="dataState.group"
      :expand-field="'expanded'"
      :sortable="true"
      :reorderable="true"
      :resizable="true"
      :groupable="true"
      :pageable="true"
      :filterable="true"
      :page-size="50"
      @datastatechange="onDataStateChange"
      @filterchange="onFilterChange"
      @sortchange="onSortChange"
      @columnreorder="onColumnReorder"
    >
      <template v-slot:cellPreCompTemplate="{ props }">
        <HumanizedByteSizeCellTemplate
          :field="props.field"
          :row-type="props.rowType"
          :format="props.format"
          :type="props.type"
          :data-item="props.dataItem"
          :value="props.dataItem.preComp"
        />
      </template>

      <template v-slot:cellPostCompTemplate="{ props }">
        <HumanizedByteSizeCellTemplate
          :field="props.field"
          :row-type="props.rowType"
          :format="props.format"
          :type="props.type"
          :data-item="props.dataItem"
          :value="props.dataItem.postComp"
        />
      </template>

      <template v-slot:cellGlobalCompPercentageTemplate="{ props }">
        <FormatCellTemplate
          :field="props.field"
          :row-type="props.rowType"
          :format="props.format"
          :type="props.type"
          :data-item="props.dataItem"
          :value="props.dataItem.globalCompPercentage"
          :append="props.dataItem.globalCompPercentage !== null ? ' %' : ''"
        />
      </template>
    </Grid>
  </div>
</template>
<script>
import { Grid } from '@progress/kendo-vue-grid'

import {
  HumanizedByteSizeCellTemplate, FormatCellTemplate,
} from '@/components/grid'
import {
  GridDefaultMixin, GridPreserveStateMixin,
} from '@/mixins/grid'

import DataDomainApexService from '@/service/datadomain-apex.service'

export default {
  components: {
    Grid,
    HumanizedByteSizeCellTemplate,
    FormatCellTemplate,
  },
  mixins: [
    GridDefaultMixin,
    GridPreserveStateMixin,
  ],
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statistic: [],
      gridId: 'apex_tree_raw_day',
      isLoading: false,
      columns: [
        {
          field: 'mtree',
          title: 'Mtree',
          orderIndex: 1,
          width: '200px',
        },
        {
          field: 'meassureTime',
          title: 'Meassure time',
          orderIndex: 2,
          width: '200px',
          filter: 'date',
          type: 'date',
          format: '{0:g}',
        },
        {
          field: 'preComp',
          title: 'Pre comp.',
          cell: 'cellPreCompTemplate',
          orderIndex: 3,
          width: '200px',
          filter: 'numeric',
          type: 'numeric',
        },
        {
          field: 'postComp',
          title: 'Post comp.',
          cell: 'cellPostCompTemplate',
          orderIndex: 4,
          width: '200px',
          filter: 'numeric',
          type: 'numeric',
        },
        {
          field: 'physicalUsedFactor',
          title: 'Physical used factor',
          orderIndex: 5,
          width: '200px',
          filter: 'numeric',
          type: 'numeric',
        },
        {
          field: 'logicalUsedFactor',
          title: 'Logical used factor',
          orderIndex: 6,
          width: '200px',
          filter: 'numeric',
          type: 'numeric',
        },
        {
          field: 'globalCompPercentage',
          title: 'Global comp.',
          cell: 'cellGlobalCompPercentageTemplate',
          orderIndex: 7,
          width: '200px',
          filter: 'numeric',
          type: 'numeric',
        },
      ],
    }
  },
  computed: {
    asupId() {
      if (this.asup) {
        return this.asup.id
      }

      return null
    },
    startDate() {
      return this.timeRange.startDate
    },
    endDate() {
      return this.timeRange.endDate
    },
    result() {
      const data = this.statistic

      /* Normalize data  */
      data.forEach(item => {
        if (item.meassureTime !== null) {
          // eslint-disable-next-line no-param-reassign
          item.meassureTime = new Date(this.$options.filters.formatDateTimeTz(item.meassureTime, this.asup.timezone))
        }
        if (item.startTime !== null) {
          // eslint-disable-next-line no-param-reassign
          item.startTime = new Date(this.$options.filters.formatDateTimeTz(item.startTime, this.asup.timezone))
        }
        if (item.endTime !== null) {
          // eslint-disable-next-line no-param-reassign
          item.endTime = new Date(this.$options.filters.formatDateTimeTz(item.endTime, this.asup.timezone))
        }
      })

      return this.optimizedProcess(data, this.dataState)
    },
  },
  watch: {
    timeRange() {
      this.loadStatistic()
    },
  },
  mounted() {
    this.loadStatistic()
  },
  methods: {
    loadStatistic() {
      this.isLoading = true
      DataDomainApexService.getStatisticListByMtreeAsync(this.asupId, {
        from: this.$moment(this.startDate).subtract(1, 'days').startOf('day').toISOString(),
        to: this.$moment(this.endDate).add(1, 'days').endOf('day').toISOString(),
      }, {
        disableTenantFilter: true,
      })
        .then(result => {
          this.statistic = result.items
          this.isLoading = false
        })
    },
  },
}
</script>
