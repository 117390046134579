// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig, parseGetParams } from './serviceManager'

export default {
  async getAllListAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params

    if (!requestConfig.params.maxResultCount) {
      requestConfig.params.maxResultCount = 50
    }

    let page = 0
    let totalCount = 0

    const result = []

    do {
      requestConfig.params.skipCount = page * requestConfig.params.maxResultCount

      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get('/autosupport/networker', requestConfig)
      totalCount = response.data.totalCount
      result.push(...response.data.items)

      page += 1
    } while (result.length < totalCount)

    return result
  },

  async getAsync(id, config) {
    const result = await axios.get(`/autosupport/networker/${id}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async triggerBuildAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.post(`/autosupport/networker/${id}/build`, {}, requestConfig)
    return result.data
  },

  async triggerBuildAllAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.post('/autosupport/networker/build', {}, requestConfig)
    return result.data
  },

  async getStatisticListAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/networker/${id}/statistic`, requestConfig)
    return result.data
  },

  async getClientMatrixAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/networker/${id}/client/matrix`, requestConfig)
    return result.data
  },

  async getClientWorkflowMatrixAsync(id, client, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/networker/${id}/client/${client}/workflow-matrix`, requestConfig)
    return result.data
  },

  async getWorkflowAsync(systemId, group, policy, workflow, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = {
      group,
      policy,
    }
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/networker/${systemId}/workflow/${workflow}`, requestConfig)
    return result.data
  },

  async getClientAsync(systemId, client, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/networker/${systemId}/client/${client}`, requestConfig)
    return result.data
  },

  async getWorkflowJobListAsync(systemId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/networker/${systemId}/workflow-job`, requestConfig)
    return result.data
  },

  async getSaveSetJobKpiAsync(systemId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/networker/${systemId}/workflow-job/saveset-job/kpi`, requestConfig)
    return result.data
  },

  async getWorkflowJobAsync(systemId, wfJobId, config) {
    const requestConfig = buildAxiosRequestConfig(config)

    const result = await axios.get(`/autosupport/networker/${systemId}/workflow-job/${wfJobId}`, requestConfig)
    return result.data
  },

  async getWorkflowJobClientListAsync(systemId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/networker/${systemId}/workflow-job/client`, requestConfig)
    return result.data
  },

  async getWorkflowJobDurationListAsync(systemId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/networker/${systemId}/workflow-job/duration`, requestConfig)
    return result.data
  },

  async getLogAsync(systemId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/networker/${systemId}/log`, requestConfig)
    return result.data
  },

  async downloadLogAsync(systemId, params, config) {
    const result = await axios.get(
      `/autosupport/networker/${systemId}/log/download`,
      buildAxiosRequestConfig(
        config,
        requestConfig => {
          // eslint-disable-next-line no-param-reassign
          requestConfig.responseType = 'arraybuffer'
          // eslint-disable-next-line no-param-reassign
          requestConfig.params = params
          // eslint-disable-next-line no-param-reassign
          requestConfig.paramsSerializer = parseGetParams
        },
      ),
    )

    return result
  },

  async getAllStatisticListAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params

    if (!requestConfig.params.maxResultCount) {
      requestConfig.params.maxResultCount = 1000
    }

    requestConfig.paramsSerializer = parseGetParams

    let page = 0
    let totalCount = 0

    const result = []

    do {
      requestConfig.params.skipCount = page * requestConfig.params.maxResultCount

      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get(`/autosupport/networker/${id}/statistic`, requestConfig)
      totalCount = response.data.totalCount
      result.push(...response.data.items)

      page += 1
    } while (result.length < totalCount)

    return result
  },

  async getFailedFull(systemId, config) {
    const requestConfig = buildAxiosRequestConfig(config)

    const result = await axios.get(`/autosupport/networker/${systemId}/failed-full`, requestConfig)
    return result.data
  },

  async excludeClientFromFailedFull(systemId, data, config) {
    const requestConfig = buildAxiosRequestConfig(config)

    const result = await axios.post(`/autosupport/networker/${systemId}/failed-full/excluded-client`, data, requestConfig)
    return result.data
  },

  async includeClientToFailedFull(systemId, client, config) {
    const requestConfig = buildAxiosRequestConfig(config)

    const result = await axios.delete(`/autosupport/networker/${systemId}/failed-full/excluded-client/${encodeURIComponent(client)}`, requestConfig)
    return result.data
  },
}
