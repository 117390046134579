<template>
  <div>
    <Grid
      :data-items="result"
      :columns="columns"
      :column-menu="true"
      :take="dataState.take"
      :skip="dataState.skip"
      :sort="dataState.sort"
      :filter="dataState.filter"
      :group="dataState.group"
      :expand-field="'expanded'"
      :sortable="true"
      :reorderable="true"
      :resizable="true"
      :groupable="false"
      :pageable="true"
      :filterable="true"
      :page-size="50"
      @datastatechange="onDataStateChange"
      @filterchange="onFilterChange"
      @sortchange="onSortChange"
      @columnreorder="onColumnReorder"
    >
      <!-- Custom toolbar -->
      <GridToolbar>
        <toolbar-item-custom-button
          label="Export"
          @click="downloadMonthlyReport"
        />

        <toolbar-item-loading-indicator
          :is-loading="isLoading"
        />
      </GridToolbar>
    </Grid>

  </div>
</template>
<script>
import { Grid, GridToolbar } from '@progress/kendo-vue-grid'

import {
  ToolbarItemCustomButton, ToolbarItemLoadingIndicator,
} from '@/components/grid'
import {
  GridDefaultMixin, GridPreserveStateMixin,
} from '@/mixins/grid'

import DataDomainApexService from '@/service/datadomain-apex.service'

export default {
  components: {
    Grid,
    GridToolbar,
    ToolbarItemCustomButton,
    ToolbarItemLoadingIndicator,
  },
  mixins: [
    GridDefaultMixin,
    GridPreserveStateMixin,
  ],
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
    time: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statistic: [],
      gridId: 'apex_tree_raw_monthly_quarter',
      isLoading: false,
      columns: [
        {
          field: 'time',
          title: 'Time',
          orderIndex: 0,
          width: '100px',
        },
        {
          field: 'mtree',
          title: 'Mtree',
          orderIndex: 1,
          width: '300px',
        },
        {
          field: 'avgPostCompGib',
          title: 'Avg. used capacity (GiB)',
          orderIndex: 2,
          width: '200px',
          filter: 'numeric',
          type: 'numeric',
        },
        {
          field: 'minPostCompGib',
          title: 'Min. used capacity (GiB)',
          orderIndex: 3,
          width: '200px',
          filter: 'numeric',
          type: 'numeric',
        },
        {
          field: 'maxPostCompGib',
          title: 'Max. used capacity (GiB)',
          orderIndex: 4,
          width: '200px',
          filter: 'numeric',
          type: 'numeric',
        },
        {
          field: 'measurementCount',
          title: 'Measurements',
          orderIndex: 5,
          width: '150px',
          filter: 'numeric',
          type: 'numeric',
        },
      ],
    }
  },
  computed: {
    asupId() {
      if (this.asup) {
        return this.asup.id
      }

      return null
    },
    result() {
      const data = this.statistic

      /* Normalize data  */
      data.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.time = this.timeColumnContent
        if (item.avgPostComp !== null) {
          // eslint-disable-next-line no-param-reassign
          item.avgPostCompGib = Math.round(item.avgPostComp / 1024 / 1024 / 1024, 0)
        }
        if (item.minPostComp !== null) {
          // eslint-disable-next-line no-param-reassign
          item.minPostCompGib = Math.round(item.minPostComp / 1024 / 1024 / 1024, 0)
        }
        if (item.maxPostComp !== null) {
          // eslint-disable-next-line no-param-reassign
          item.maxPostCompGib = Math.round(item.maxPostComp / 1024 / 1024 / 1024, 0)
        }
      })

      return this.optimizedProcess(data, this.dataState)
    },
    timeColumnContent() {
      if (this.time.type === 'month') {
        return `${this.time.month}.${this.time.year}`
      } if (this.time.type === 'quarter') {
        return `Q${this.time.quarter} ${this.time.year}`
      }

      return ''
    },
  },
  watch: {
    time() {
      this.loadStatistic()
    },
  },
  mounted() {
    this.loadStatistic()
  },
  methods: {
    loadStatistic() {
      this.isLoading = true

      let getStatistic = null
      if (this.time.type === 'month') {
        getStatistic = DataDomainApexService.getStatisticDataPerMonth(this.asupId, {
          month: this.time.month,
          year: this.time.year,
        }, {
          disableTenantFilter: true,
        })
      } else if (this.time.type === 'quarter') {
        getStatistic = DataDomainApexService.getStatisticDataPerQuarter(this.asupId, {
          quarter: this.time.quarter,
          year: this.time.year,
        }, {
          disableTenantFilter: true,
        })
      }

      if (getStatistic) {
        getStatistic
          .then(result => {
            this.statistic = result.items
            this.isLoading = false
          })
      }
    },
    downloadMonthlyReport() {
      let download = null
      if (this.time.type === 'month') {
        download = DataDomainApexService.downloadStatisticDataPerMonth(this.asupId, {
          month: this.time.month,
          year: this.time.year,
        }, {
          disableTenantFilter: true,
        })
      } else if (this.time.type === 'quarter') {
        download = DataDomainApexService.downloadStatisticDataPerQuarter(this.asupId, {
          quarter: this.time.quarter,
          year: this.time.year,
        }, {
          disableTenantFilter: true,
        })
      }

      download
        .then(result => {
          const filename = result.headers['content-disposition'].split('filename=')[1].split(';')[0]
          const url = window.URL.createObjectURL(new Blob([result.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          link.remove()// you need to remove that elelment which is created before.
        })
    },
  },
}
</script>
