<template>
  <b-card
    title="Assets"
  >
    <b-overlay :show="isLoading">
      <Grid
        class="grid-full-height"
        :data-items="result"
        :columns="columns"
        :column-menu="true"
        :take="dataState.take"
        :skip="dataState.skip"
        :sort="dataState.sort"
        :filter="dataState.filter"
        :group="dataState.group"
        :sortable="true"
        :reorderable="true"
        :resizable="true"
        :groupable="false"
        :pageable="true"
        :filterable="true"
        :page-size="50"
        @datastatechange="onDataStateChange"
        @filterchange="onFilterChange"
        @sortchange="onSortChange"
        @columnreorder="onColumnReorder"
      >
        <!-- Custom toolbar -->
        <GridToolbar>

          <toolbar-item-view-manager
            :grid-id="gridId"
            :columns="columns"
            :data-state="dataState"
            :original-columns="originalColumns"
            :original-data-state="originalDataState"
            :current-view-name="currentViewName"
            :current-view-id="currentViewId"
            @resetToDefaultViewClick="resetToDefaultView"
            @applyView="onApplyView"
          />

          <toolbar-item-column-manager
            :columns="activeColumns"
            :original-columns="originalColumns"
            @columnssubmit="columns = $event"
          />

          <toolbar-item-export-excel
            :data-items="dataItems"
            :columns="activeColumns"
            :data-state="dataState"
          />

          <template>
            <div
              style="margin-left: auto"
            >
              <b-button-group>
                <b-button
                  size="sm"
                  :variant="selectedFilter === 'active' ? 'primary' : 'secondary'"
                  @click="selectedFilter = 'active'"
                >
                  Active
                </b-button>
                <b-button
                  size="sm"
                  :variant="selectedFilter === 'inactive' ? 'primary' : 'secondary'"
                  @click="selectedFilter = 'inactive'"
                >
                  Inactive
                </b-button>
              </b-button-group>
            </div>
          </template>

        </GridToolbar>

        <!-- Custom action cell -->
        <template v-slot:cellActionTemplate="{ props }">
          <detail-link-action-cell-template
            :field="props.field"
            :row-type="props.rowType"
            :class-name="props.className"
            to="asset-detail-page"
            :to-params="{ id: props.dataItem.id}"
          />
        </template>
      </Grid>
    </b-overlay>
  </b-card>
</template>
<script>
import {
  BCard, BButton, BButtonGroup, BOverlay,
} from 'bootstrap-vue'
import { Grid, GridToolbar } from '@progress/kendo-vue-grid'

import {
  DetailLinkActionCellTemplate, ToolbarItemViewManager, ToolbarItemColumnManager, ToolbarItemExportExcel,
} from '@/components/grid'
import {
  GridDefaultMixin, GridPreserveStateMixin,
} from '@/mixins/grid'

import AssetService from '@/service/asset.service'

export default {
  components: {
    BCard,
    BButton,
    BButtonGroup,
    BOverlay,
    Grid,
    GridToolbar,
    DetailLinkActionCellTemplate,
    ToolbarItemExportExcel,
    ToolbarItemColumnManager,
    ToolbarItemViewManager,
  },
  mixins: [
    GridDefaultMixin,
    GridPreserveStateMixin,
  ],
  data() {
    return {
      gridId: 'tenant_asset',
      selectedFilter: 'active',
      isLoading: false,
      columns: [
        {
          cell: 'cellActionTemplate',
          field: 'cellActionTemplate',
          title: ' ',
          locked: true,
          filterable: false,
          sortable: false,
          groupable: false,
          width: '40px',
          orderIndex: 0,
          static: true,
        },
        {
          field: 'account.name',
          title: 'Account',
          orderIndex: 1,
          width: '300px',
        },
        {
          field: 'organizationUnit.displayName',
          title: 'Organization unit',
          orderIndex: 2,
          width: '300px',
        },
        {
          field: 'systemNumber',
          title: 'Systemnumber',
          orderIndex: 3,
          width: '200px',
        },
        {
          field: 'manufacturer',
          title: 'Manufacturer',
          orderIndex: 4,
          width: '150px',
        },
        {
          field: 'productFamily',
          title: 'Product family',
          orderIndex: 5,
          width: '150px',
        },
        {
          field: 'model',
          title: 'Model',
          orderIndex: 6,
          width: '150px',
        },
        {
          field: 'hostname',
          title: 'Hostname',
          filter: 'text',
          orderIndex: 7,
          width: '300px',
        },
        {
          field: 'hostId',
          title: 'Host Id',
          filter: 'text',
          orderIndex: 8,
          width: '200px',
        },
        {
          field: 'version',
          title: 'Version',
          orderIndex: 9,
          width: '200px',
        },
        {
          field: 'serialnumber',
          title: 'Serialnumber',
          orderIndex: 10,
          width: '200px',
        },
        {
          field: 'ipAddress',
          title: 'IP Address',
          orderIndex: 11,
          width: '150px',
        },
        {
          field: 'location',
          title: 'Location',
          orderIndex: 123,
          width: '150px',
        },
        {
          field: 'licensedCapacityTb',
          title: 'Licensed Capacity (Tb)',
          orderIndex: 13,
          width: '150px',
          filter: 'numeric',
          type: 'numeric',
        },
        {
          field: 'measuredCapacityTb',
          title: 'Measured Capacity (Tb)',
          orderIndex: 14,
          width: '150px',
          filter: 'numeric',
          type: 'numeric',
        },
        {
          field: 'clients',
          title: 'Clients',
          orderIndex: 15,
          width: '100px',
          filter: 'numeric',
          type: 'numeric',
        },
        {
          field: 'expiresOn',
          title: 'Service expires on',
          orderIndex: 16,
          width: '150px',
          filter: 'date',
          type: 'date',
          format: '{0:d}',
        },
        {
          field: 'hasProActiveService',
          title: 'Has ProActive Service',
          orderIndex: 17,
          width: '150px',
          filter: 'text',
          type: 'boolean',
          columnMenu: false,
        },
      ],
    }
  },
  computed: {
    result() {
      let assets = this.dataItems

      if (this.selectedFilter === 'active') {
        assets = assets.filter(x => x.status === 1)
      } else if (this.selectedFilter === 'inactive') {
        assets = assets.filter(x => x.status === 2)
      }

      /* Normalize data  */
      /* DateTimes must be Date objects to work with grid filters. Booleans doesnt work (Telerik bug?), so we convert boolean to string so that we can filter for string */
      assets.forEach(item => {
        if (item.expiresOn !== null) {
          // eslint-disable-next-line no-param-reassign
          item.expiresOn = new Date(item.expiresOn)
        }
        if (item.autosupportUpdatedOn !== null) {
          // eslint-disable-next-line no-param-reassign
          item.autosupportUpdatedOn = new Date(item.autosupportUpdatedOn)
        }
        if (item.hasProActiveService !== null) {
          // eslint-disable-next-line no-param-reassign
          item.hasProActiveService = item.hasProActiveService === true ? 'true' : 'false'
        }
      })

      return this.optimizedProcess(assets, this.dataState)
    },
  },
  created() {
    this.isLoading = true
    AssetService.getAllListAsync({ includeDefaultOu: true }, this.getRequestConfig())
      .then(result => {
        this.dataItems = result
      })
      .finally(() => {
        this.isLoading = false
      })
  },
}
</script>
