import { mapGetters } from 'vuex'
import { process } from '@progress/kendo-data-query'
import GridHelper from '@/utils/gridUtils'

export default {
  props: {
    impersonateAsTenant: {
      type: String,
      default: null,
    },
    disableTenantFilter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
    filteredDataitems() {
      return this.dataItems
    },
    activeColumns() {
      return this.columns.filter(
        c => c.field !== null && typeof c.field !== 'undefined',
      )
    },
    result() {
      return process(this.filteredDataitems, this.dataState)
    },
  },
  data() {
    return {
      isLoading: false,
      dataState: GridHelper.createDefaultDataState(),
      dataItems: [],
      columns: [],
      currentViewName: null,
      currentViewId: null,
    }
  },
  created() {

  },
  mounted() {
    this.applyColumnMeta()
  },
  methods: {
    optimizedProcess(data, state) {
      const dataStateCopy = JSON.parse(JSON.stringify(state)) // create copy of original datastate
      if (dataStateCopy.filter
      && dataStateCopy.filter.filters
      && dataStateCopy.filter.filters.length > 0
      && dataStateCopy.filter.filters[0].value
      && typeof dataStateCopy.filter.filters[0].value === 'string') {
        dataStateCopy.filter.filters[0].value = dataStateCopy.filter.filters[0].value.trim()
      }
      return process(data, dataStateCopy)
    },
    getRequestConfig() {
      return {
        disableTenantFilter: this.disableTenantFilter,
        impersonateAsTenant: this.impersonateAsTenant,
      }
    },
    onApplyView(e) {
      this.updateState(e.view.columns, e.view.dataState, e.displayName, e.id)
      GridHelper.highlightColumnsWithSortOrFilter(this.columns, this.dataState)

      // View applied. Save current Grid state (call function from GridPreserveStateMixin, but only if this mixin is applied to the grid)
      if (typeof this.saveCurrentGridState === 'function') {
        this.saveCurrentGridState()
      }
    },
    updateState(cols, dataState, viewName, viewId) {
      this.dataState = JSON.parse(JSON.stringify(dataState)) // create copy of original datastate

      this.currentViewName = viewName
      this.currentViewId = viewId

      const filteredCols = cols.filter(c => c.field !== null && typeof c.field !== 'undefined')
      for (let i = 0; i < filteredCols.length; i += 1) {
        const col = filteredCols[i]
        for (let j = 0; j < this.activeColumns.length; j += 1) {
          const activeColumn = this.activeColumns[j]
          if (activeColumn.field === col.field) {
            activeColumn.orderIndex = col.orderIndex
            activeColumn.locked = col.locked
            activeColumn.width = col.width
            activeColumn.hidden = col.hidden
          }
        }
      }

      this.applyColumnMeta()
    },
    applyColumnMeta() {
      for (let j = 0; j < this.activeColumns.length; j += 1) {
        const activeColumn = this.activeColumns[j]
        if (activeColumn.meta) {
          if (activeColumn.meta.defaultHideForHost && this.isHost) {
            activeColumn.hidden = true
          }
          if (activeColumn.meta.defaultHideForTenant && !this.isHost) {
            activeColumn.hidden = true
          }
        }
      }

      if (!this.isHost) { // is tenant
        this.columns = this.columns.filter(x => !x.meta || (x.meta && !x.meta.removeForTenant))
      }
      if (this.isHost) { // is host
        this.columns = this.columns.filter(x => !x.meta || (x.meta && !x.meta.removeForHost))
      }
    },
  },
}
