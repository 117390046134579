<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-form inline>
            <b-form-group>
              <b-form-checkbox
                v-model="unacknowledgedOnly"
              >
                Show only unacknowledged alerts
              </b-form-checkbox>
            </b-form-group>
            <b-form-group class="ml-1">
              <b-form-checkbox
                v-model="errorsOnly"
              >
                Errors only
              </b-form-checkbox>
            </b-form-group>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <Grid
            class="grid-full-height"
            :data-items="result"
            :columns="columns"
            :column-menu="true"
            :take="dataState.take"
            :skip="dataState.skip"
            :sort="dataState.sort"
            :filter="dataState.filter"
            :group="dataState.group"
            :sortable="true"
            :reorderable="true"
            :resizable="true"
            :groupable="false"
            :pageable="true"
            :filterable="true"
            :page-size="50"
            @datastatechange="onDataStateChange"
          >
            <template v-slot:cellActionTemplate="{ props }">
              <DetailLinkActionCellTemplate
                :field="props.field"
                :row-type="props.rowType"
                :class-name="props.className"
                @click="openEventDetails(props.dataItem.id)"
              />
            </template>

            <template v-slot:messageCell="{ props }">
              <TextPopupCellTemplate
                :id="props.dataItem.id"
                :field="props.field"
                :row-type="props.rowType"
                popover-title="Message"
                :text="props.dataItem.message"
                :preview-length="200"
              />
            </template>

          </Grid>
        </b-card>
      </b-col>
    </b-row>

    <b-sidebar
      v-model="showSidebar"
      title="Alert details"
      width="400px"
      right
      shadow
      lazy
    >
      <div
        v-if="selectedEvent"
        class="px-3 py-2"
      >
        <dl>
          <dt>Timestamp</dt>
          <dd>{{ selectedEvent.postedTime | formatDateTimeTz(asup.timezone) }}</dd>

          <dt>Severity</dt>
          <dd>{{ selectedEvent.severity }}</dd>

          <dt>Category</dt>
          <dd>{{ selectedEvent.category }}</dd>
        </dl>

        <hr>

        <dl>
          <dt>Message Id</dt>
          <dd>{{ selectedEvent.messageId }}</dd>

          <dt>Message</dt>
          <dd>{{ selectedEvent.message }}</dd>

          <template v-if="selectedEvent.detailedDescription">
            <dt>Detailed Description</dt>
            <dd>{{ selectedEvent.detailedDescription }}</dd>
          </template>

          <template v-if="selectedEvent.responseAction">
            <dt>Response Action</dt>
            <dd>{{ selectedEvent.responseAction }}</dd>
          </template>
        </dl>

        <hr>

        <dl>
          <dt>Acknowledged</dt>
          <dd>{{ selectedEvent.acknowledged }}</dd>

          <template v-if="selectedEvent.acknowledgement && selectedEvent.acknowledgement.acknowledgeTime">
            <dt>Acknowledged on</dt>
            <dd>{{ selectedEvent.acknowledgeTime | formatDateTimeTz(asup.timezone) }}</dd>
          </template>

          <template v-if="selectedEvent.acknowledgement && selectedEvent.acknowledgement.acknowledgedBy">
            <dt>Acknowledged By</dt>
            <dd>{{ selectedEvent.acknowledgement.acknowledgedBy }}</dd>
          </template>
        </dl>
      </div>
    </b-sidebar>
  </div>
</template>

<script>

import {
  BRow, BCol, BCard, BForm, BFormCheckbox, BFormGroup, BSidebar,
} from 'bootstrap-vue'
import { Grid } from '@progress/kendo-vue-grid'
import {
  GridDefaultMixin,
} from '@/mixins/grid'
import {
  TextPopupCellTemplate, DetailLinkActionCellTemplate,
} from '@/components/grid'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BSidebar,
    Grid,
    TextPopupCellTemplate,
    DetailLinkActionCellTemplate,
  },
  mixins: [
    GridDefaultMixin,
  ],
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showSidebar: false,
      selectedEvent: null,
      gridId: 'ppdm_alerts',
      unacknowledgedOnly: true,
      errorsOnly: true,
      dataState: {
        sort: [{
          field: 'localPostedTime',
          dir: 'desc',
        }],
      },
      columns: [
        {
          cell: 'cellActionTemplate',
          field: 'cellActionTemplate',
          locked: true,
          title: ' ',
          filterable: false,
          sortable: false,
          groupable: false,
          width: '40px',
          orderIndex: 0,
          columnMenu: false,
          static: true,
        },
        {
          field: 'acknowledged',
          title: 'Ack.',
          orderIndex: 1,
          width: '80px',
          filter: 'boolean',
          type: 'boolean',
          filterable: false,
          sortable: false,
          columnMenu: false,
        },
        {
          field: 'localPostedTime',
          title: 'Timestamp',
          orderIndex: 2,
          width: '200px',
          filter: 'date',
          type: 'date',
          format: '{0:g}',
        },
        {
          field: 'severity',
          title: 'Severity',
          orderIndex: 3,
          width: '150px',
        },
        {
          field: 'category',
          title: 'Category',
          orderIndex: 4,
          width: '200px',
        },
        {
          field: 'message',
          title: 'Message',
          orderIndex: 6,
          width: '800px',
          cell: 'messageCell',
        },
      ],
    }
  },
  computed: {
    result() {
      let data = this.asup.details.alerts
      data.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.localPostedTime = this.$options.filters.formatDateTimeTz(item.postedTime, this.asup.timezone)
      })

      if (this.errorsOnly === true) {
        data = data.filter(x => x.severity === 'CRITICAL' || x.severity === 'WARNING')
      }

      if (this.unacknowledgedOnly === true) {
        data = data.filter(x => x.acknowledged === false)
      }

      return this.optimizedProcess(data, this.dataState)
    },
  },
  methods: {
    onDataStateChange(e) {
      this.dataState = e.data
    },
    openEventDetails(eventId) {
      this.selectedEvent = this.asup.details.alerts.find(x => x.id === eventId)
      this.showSidebar = true
    },
  },
}
</script>

<style scoped>
  .config-table-wrapper {
    max-height: 400px;
    overflow-y: auto
  }
</style>
