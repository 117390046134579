<template>
  <td
    v-if="renderCell"
    class="p-0"
  ><div v-if="isValidForDailyCheck">
    <div v-if="!isLoading">

      <b-button
        v-if="todayCheckupStatus === null"
        squared
        variant="transparent"
        :class="['dailycheck-btn', { 'p-0  d-flex flex-column align-items-center': validToShowRms }]"
        @click="$emit('click')"
      >
        <feather-icon
          icon="HeartIcon"
        />
        <BBadge
          v-if="validToShowRms"
          variant="primary"
          style="font-size: 6px; margin-top: 2px; padding: 4px"
        >
          RMS
        </BBadge>
      </b-button>

      <b-button
        v-if="todayCheckupStatus === 0"
        squared
        variant="success"
        :class="['dailycheck-btn', { 'p-0  d-flex flex-column align-items-center': validToShowRms }]"
        @click="$emit('click')"
      >
        <feather-icon
          class="filled"
          icon="HeartIcon"
        />
        <BBadge
          v-if="validToShowRms"
          variant="primary"
          style="font-size: 6px; margin-top: 2px; padding: 4px"
        >
          RMS
        </BBadge>
      </b-button>

      <b-button
        v-if="todayCheckupStatus === 1"
        squared
        variant="warning"
        :class="['dailycheck-btn', { 'p-0  d-flex flex-column align-items-center': validToShowRms }]"
        @click="$emit('click')"
      >
        <feather-icon
          class="filled"
          icon="HeartIcon"
        />
        <BBadge
          v-if="validToShowRms"
          variant="primary"
          style="font-size: 6px; margin-top: 2px; padding: 4px"
        >
          RMS
        </BBadge>
      </b-button>

      <b-button
        v-if="todayCheckupStatus === 2"
        squared
        variant="danger"
        :class="['dailycheck-btn', { 'p-0  d-flex flex-column align-items-center': validToShowRms }]"
        @click="$emit('click')"
      >
        <feather-icon
          class="filled"
          icon="HeartIcon"
        />
        <BBadge
          v-if="validToShowRms"
          variant="primary"
          style="font-size: 6px; margin-top: 2px; padding: 4px"
        >
          RMS
        </BBadge>
      </b-button>
    </div>
    <div
      v-else
      style="text-align: center"
    >
      <feather-icon
        icon="LoaderIcon"
        class="spinner"
      />
      <BBadge
        v-if="validToShowRms"
        variant="primary"
        style="font-size: 6px; margin-top: 2px; padding: 4px"
      >
        RMS
      </BBadge>
    </div>
  </div>
  </td>
</template>
<script>

import { BButton, BBadge } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BButton,
    BBadge,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    rowType: {
      type: String,
      required: true,
    },
    hasProActiveService: {
      type: Boolean,
      required: true,
      default: false,
    },
    hasRms: {
      type: Boolean,
      required: true,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    todayCheckupStatus: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
    isValidForDailyCheck() {
      // host can check ProActive service assets
      if (this.hasProActiveService && this.isHost) {
        return true
      }

      // tenant can check none ProActive service assets
      if (this.hasProActiveService === false && this.isHost === false) {
        return true
      }

      return false
    },
    validToShowRms() {
      return this.hasRms && this.isHost
    },
    renderCell() {
      return this.field !== undefined && this.rowType !== 'groupHeader'
    },
  },
}
</script>

<style scoped>
    .dailycheck-btn {
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
    }

    .filled {
      fill: #ffffff;
      fill-opacity: 0.9;
    }
</style>
